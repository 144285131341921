import __NS_DYNAMIC_REACT_CONTENT___0 from "./ada-chatbot.strings/content.default.js";
import { Content, createScopedReactContentImporter } from "packages/react-content/runtime";
const NS_DYNAMIC_REACT_CONTENT_MANAGER = createScopedReactContentImporter(undefined, {
  "default": () => __NS_DYNAMIC_REACT_CONTENT___0
});
import { createElement } from 'react';
import { AdaChatbotPlugin } from 'packages/ada-chatbot/plugin';
import { ChatContainer, ChatNetspendContainer, ChatDesktopIcon, ChatNetspendDesktopIcon, ChatMobileIcon, ChatNetspendMobileIcon } from './ada-chatbot.ccm.css';
import { AdaChatIcons, AdaNetspendChatIcons } from './icons';
import { useHttpQuery } from 'packages/http-client/react';
import { createGetBrandingRequest } from 'apps/acp/packages/webapi';
export const useAdaChatbotAnchor = callback => {
  return {
    href: 'javascript:void(0)',
    onClick: () => {
      var _a;

      callback && callback();
      (_a = AdaChatbotPlugin) === null || _a === void 0 ? void 0 : _a.toggle();
    }
  };
};
export const AdaCustomChatButton = ({
  leftIconColor,
  rightIconColor
}) => {
  var _a, _b;

  const netspendBrand = 'netspend_unauthenticated_default';
  const brand = useHttpQuery(createGetBrandingRequest());
  const isNetspendBrand = netspendBrand === ((_b = (_a = brand) === null || _a === void 0 ? void 0 : _a.brand) === null || _b === void 0 ? void 0 : _b.id);
  const DesktopIcon = AdaChatIcons.chat;
  const MobileIcon = AdaChatIcons.chatMobile;
  const NetspendIcon = AdaNetspendChatIcons.chatNetspend;
  return createElement(Content, {
    hash: "008b20ccf81de7a854f29dec43420d6f",
    devVariables: {},
    componentsAndExpressions: [() => isNetspendBrand && createElement(ChatNetspendContainer.div, {
      onClick: () => window.adaEmbed.toggle()
    }, createElement(ChatNetspendDesktopIcon.i, null, createElement(NetspendIcon, {
      width: "31",
      height: "31"
    })), createElement(ChatNetspendMobileIcon.i, null, createElement(NetspendIcon, {
      width: "25",
      height: "25"
    }))), () => !isNetspendBrand && createElement(ChatContainer.div, {
      onClick: () => window.adaEmbed.toggle()
    }, createElement(ChatDesktopIcon.i, null, createElement(DesktopIcon, {
      leftIcon: leftIconColor,
      rightIcon: rightIconColor
    })), createElement(ChatMobileIcon.i, null, createElement(MobileIcon, {
      leftIcon: leftIconColor,
      rightIcon: rightIconColor
    })))],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  });
};